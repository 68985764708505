<template>
  <footer class="footer-03 py-2">
    <div class="d-flex">
      <div class="m-auto text-center">
        <img
          class="mx-1 opacity-50"
          v-for="image in sponsors"
          :key="image"
          height="50"
          :src="image"
          alt=""
        />
      </div>
    </div>

    <div class="d-flex pt-3">
      <hr class="text-light m-auto w-75" />
    </div>
    <div class="container pt-3">
      <div class="row">
        <div class="col-md-6">
          <div class="row d-flex">
            <div class="col-6 mb-md-0 mb-4">
              <h2 class="footer-heading">Menu</h2>
              <ul class="list-unstyled">
                <li v-for="page in menu" v-bind:key="page.title">
                  <router-link class="py-1 d-block" v-bind:to="page.link"
                    >{{ page.title }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-6 mb-md-0 mb-4">
              <h2 class="footer-heading">Suivez nous</h2>
              <div class="col-12 icon-footer">
                <a class="mx-1" target="_blank" :href="lienFacebook">
                  <span
                    ><font-awesome-icon :icon="['fab', 'facebook-square']"
                  /></span>
                </a>
                <a :href="lienInstagram" target="_blank" class="mx-1">
                  <span
                    ><font-awesome-icon :icon="['fab', 'instagram-square']"
                  /></span>
                </a>
                <a :href="lienTwitter" target="_blank" class="mx-1">
                  <span
                    ><font-awesome-icon :icon="['fab', 'twitter-square']"
                  /></span>
                </a>
                <a href="/basketball">
                  <img class="m-2 opacity-50 img-icon" :src="logoBasketBall" />
                </a>
                <a href="/football">
                  <img class="m-2 opacity-50 img-icon" :src="logoFootball" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 pt-4 border-top">
        <div class="col-md-6 col-lg-4 text-md-right">
          <p class="mb-0 list-unstyled">
            <a class="mr-md-3" href="#">Terms &amp; Conditions</a>
            <a class="mr-md-3" href="#">Privacy</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      lienFacebook: "",
      lienInstagram: "",
      lienTwitter: "",
      sport: "",
      logoGenerale:
        "https://firebasestorage.googleapis.com/v0/b/folclobasket-be9d5.appspot.com/o/folclogengeral.png?alt=media&token=15a74ba3-c855-42ef-93b4-faf43431f7de",
      menu: {},
      logo: "",
      logoBasketBall:
        "https://firebasestorage.googleapis.com/v0/b/folclobasket-be9d5.appspot.com/o/basketball%2FlogoBasketpng%20(3).png?alt=media&token=7750e6b9-fb80-4205-b256-a269f679015e",
      logoFootball:
        "https://firebasestorage.googleapis.com/v0/b/folclobasket-be9d5.appspot.com/o/football%2Flogobleufoot%20(2).png?alt=media&token=41fb5d12-13a0-453c-b65f-5bc5517de04d",
      sponsors: [],
    };
  },
  computed: {
    menus() {
      return this.$store.state.menus;
    },
  },
  methods: {
    async getSiteText(sport) {
      return this.$store.getters.getSiteText(sport);
    },
  },
  async mounted() {
    this.sport = window.location.href.includes("basketball")
      ? "basketball"
      : "football";
    this.lienFacebook =
      this.sport === "basketball"
        ? "https://www.facebook.com/search/top?q=folclo basketball"
        : "https://www.facebook.com/search/top?q=folclo football";

    this.lienInstagram =
      this.sport === "basketball"
        ? "https://www.instagram.com/folclobasket/"
        : "https://www.instagram.com/folclofoot/";

    this.lienTwitter =
      this.sport === "basketball"
        ? "https://twitter.com/folclobasket"
        : "https://twitter.com/folclofoot";

    this.menu = this.menus[this.sport];
    var siteText = await this.getSiteText(this.sport);
    this.sponsors = siteText?.sponsors;
    this.logo = siteText.logopng;
  },
};
</script>
