<template>
  <div>
    <menu-2 v-if="$route.name != 'Home'" />
    <router-view class="margin-top-header" />
    <Footer v-if="$route.name != 'Home'" />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "./components/Footer.vue";
import Menu2 from "./components/Menu2.vue";

export default {
  name: "App",
  data() {
    return {
      selectsection: true,
    };
  },
  components: {
    Footer,
    Menu2,
  },
  computed: {
    selectsection() {
      return this.selectsection;
    },
  },
};
</script>

<style>
/*@import "assets/css/bootstrap/bootstrap.css";
@import "assets/css/jquery-ui.css";
@import "assets/css/owl.carousel.min.css";
@import "assets/css/owl.theme.default.min.css";
@import "assets/css/owl.theme.default.min.css";

@import "assets/css/jquery.fancybox.min.css";

@import "assets/css/bootstrap-datepicker.css";*/

@import "assets/css/aos.css";

@import "assets/css/style.css";

import * #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
