<template>
  <div>
    <div ref="sideNav" class="bm-menu">
      <nav class="bm-item-list" @click="closeMenu">
        <slot></slot>
      </nav>
      <span
        :style="{ right: '10px' }"
        class="bm-cross-button cross-style"
        @click="closeMenu"
        :class="{ hidden: !crossIcon }"
      >
        <span
          v-for="(x, index) in 2"
          :key="x"
          class="bm-cross"
          :style="{
            position: 'absolute',
            width: '3px',
            height: '20px',
            transform: index === 1 ? 'rotate(45deg)' : 'rotate(-45deg)',
          }"
        >
        </span>
      </span>
      <div class="position-absolute" style="bottom: 10vh">
        <router-link :to="'/' + sport" @click="closeMenu"
          ><img class="w-75" :src="logo" alt="" />
        </router-link>
      </div>
    </div>

    <div
      ref="bmBurgerButton"
      class="bm-burger-button"
      @click="openMenu"
      :class="{ hidden: !burgerIcon }"
    >
      <span
        class="bm-burger-bars line-style"
        :style="{ top: 20 * (index * 2) + '%' }"
        v-for="(x, index) in 3"
        :key="index"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "menubar",
  data() {
    return {
      isSideBarOpen: false,
      logo: "",
      sport: "",
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
    },
    right: {
      type: Boolean,
      required: false,
    },
    width: {
      type: [String],
      required: false,
      default: "300",
    },
    disableEsc: {
      type: Boolean,
      required: false,
    },
    noOverlay: {
      type: Boolean,
      required: false,
    },
    onStateChange: {
      type: Function,
      required: false,
    },
    burgerIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    crossIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    disableOutsideClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnNavigation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async getSiteText(sport) {
      return this.$store.getters.getSiteText(sport);
    },
    openMenu() {
      this.$emit("openMenu");
      this.isSideBarOpen = true;
      if (!this.noOverlay) {
        document.getElementsByClassName("bm-overlay")[0].style.display =
          "block";
      }
      if (this.right) {
        this.$refs.sideNav.style.left = "auto";
        this.$refs.sideNav.style.right = "0px";
      }
      this.$nextTick(function () {
        this.$refs.sideNav.style.width = this.width
          ? this.width + "px"
          : "300px";
      });
    },

    closeMenu() {
      this.$emit("closeMenu");
      this.isSideBarOpen = false;
      document.getElementsByClassName("bm-overlay")[0].style.display = "none";
      this.$refs.sideNav.style.width = "0px";
    },

    closeMenuOnEsc(e) {
      e = e || window.event;
      if (e.key === "Escape" || e.keyCode === 27) {
        this.closeMenu();
      }
    },
    documentClick(e) {
      let element = this.$refs.bmBurgerButton;
      let target = null;
      if (e && e.target) {
        target = e.target;
      }

      if (
        element &&
        element !== target &&
        !element.contains(target) &&
        !this.hasClass(target, "bm-menu") &&
        this.isSideBarOpen &&
        !this.disableOutsideClick
      ) {
        this.closeMenu();
      } else if (
        element &&
        this.hasClass(target, "bm-menu") &&
        this.isSideBarOpen &&
        this.closeOnNavigation
      ) {
        this.closeMenu();
      }
    },
    hasClass(element, className) {
      do {
        if (element.classList && element.classList.contains(className)) {
          return true;
        }
        element = element.parentNode;
      } while (element);
      return false;
    },
  },

  async mounted() {
    if (!this.disableEsc) {
      document.addEventListener("keyup", this.closeMenuOnEsc);
    }
    this.sport = window.location.href.includes("basketball")
      ? "basketball"
      : "football";
    //console.log(await this.getSiteText(sport));
    this.logo = (await this.getSiteText(this.sport))?.logopng;
  },
  created: function () {
    document.addEventListener("click", this.documentClick);
  },
  destroyed: function () {
    document.removeEventListener("keyup", this.closeMenuOnEsc);
    document.removeEventListener("click", this.documentClick);
  },
  watch: {
    isOpen: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        this.$nextTick(() => {
          if (!oldValue && newValue) {
            this.openMenu();
          }
          if (oldValue && !newValue) {
            this.closeMenu();
          }
        });
      },
    },
    right: {
      deep: true,
      immediate: true,
      handler(oldValue, newValue) {
        if (oldValue) {
          this.$nextTick(() => {
            this.$refs.bmBurgerButton.style.left = "auto";
            this.$refs.bmBurgerButton.style.right = "0px";
            this.$refs.sideNav.style.left = "auto";
            this.$refs.sideNav.style.right = "0px";
            document.querySelector(".bm-burger-button").style.left = "auto";
            document.querySelector(".bm-burger-button").style.right = "5px";
            document.querySelector(".bm-menu").style.left = "auto";
            document.querySelector(".bm-menu").style.right = "0px";
            document.querySelector(".cross-style").style.right = "15px";
          });
        }
        if (newValue) {
          if (this.$refs.bmBurgerButton.hasAttribute("style")) {
            this.$refs.bmBurgerButton.removeAttribute("style");
            this.$refs.sideNav.style.right = "auto";
            document
              .querySelector(".bm-burger-button")
              .removeAttribute("style");
            document.getElementById("sideNav").style.right = "auto";
            document.querySelector(".cross-style").style.right = "0px";
          }
        }
      },
    },
  },
};
</script>

<style>
html {
  height: 100%;
}
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 15px;
  cursor: pointer;
}
.bm-burger-button.hidden {
  display: none;
}
.bm-burger-bars {
  background-color: #0b0f66;
}
.line-style {
  position: absolute;
  height: 20%;
  left: 0;
  right: 0;
}
.cross-style {
  position: absolute;
  top: 12px;
  right: 2px;
  cursor: pointer;
}
.bm-cross {
  background: #bdc3c7;
}
.bm-cross-button {
  height: 24px;
  width: 24px;
}
.bm-cross-button.hidden {
  display: none;
}
.bm-menu {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #0b0f66; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  position: absolute;
  display: none;
}
.bm-item-list {
  color: #b8b7ad;
  margin-left: 10%;
  font-size: 20px;
}
.bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.7em;
}
.bm-item-list > * > span {
  margin-left: auto;
  margin-right: 10px;
  font-weight: 700;
  color: white;
  font-size: 1.25rem;
}
</style>
