<template>
  <header
    class="site-navbar bg-white p-1"
    role="banner"
    :class="{ 'site-navbar--hidden': !showNavbar }"
  >
    <div class="bm-overlay"></div>
    <div class="col-12 menu-bar">
      <router-link
        class="position-absolute p-3"
        :to="!isAuthentificate ? '/' + sport + '/login' : '/' + sport"
        style="left: 0px; top: 0px; color: #040523"
      >
        <font-awesome-icon
          @click="logout"
          v-if="isAuthentificate"
          class="image-icon"
          :icon="['fas', 'right-from-bracket']"
        />
        <font-awesome-icon
          v-if="!isAuthentificate"
          class="image-icon"
          :icon="['fas', 'user']"
        />
      </router-link>
      <div class="site-logo mt-2 m-auto">
        <router-link :to="'/' + sport">
          <img class="logo-img" :src="logo" alt="Logo" />
        </router-link>
      </div>
      <button
        @click="open"
        class="navbar-toggler button-phone"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <slide right>
          <router-link
            v-for="page in menu"
            v-bind:key="page.title"
            v-bind:to="page.link"
            ><span class="menu-text">{{ page.title }}</span>
          </router-link>
        </slide>
      </button>

      <nav
        class="navbar navbar-expand-lg site-navigation position-relative text-right p-0"
        role="navigation"
      >
        <div class="collapse navbar-collapse show" id="navbarSupportedContent">
          <ul class="navbar-nav site-menu menu-folclo d-none d-lg-block mt-0">
            <li class="nav-item" v-for="page in menu" v-bind:key="page.title">
              <router-link v-bind:to="page.link">
                <p
                  class="menu-text"
                  :class="{ 'active-item': isActiveMenuItem(page.link) }"
                >
                  {{ page.title }}
                </p>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import Slide from "./slide.vue";

export default {
  name: "Menu2",
  components: {
    Slide,
    // Register your component
  },
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      logo: "",
      window: {
        width: 0,
        height: 0,
      },
      open: false,
      menu: {},
      sport: "",
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.sport = window.location.href.includes("basketball")
      ? "basketball"
      : "football";

    this.menu = this.menus[this.sport];
    this.logo = (await this.getSiteText(this.sport))?.logo;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
    isActiveMenuItem(link) {
      return link === window.location.pathname;
    },
    async getSiteText(sport) {
      return this.$store.getters.getSiteText(sport);
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    async logout() {
      await this.$store.getters.logout();
      this.$store.getters.setAuthentification(false);
    },
  },
  computed: {
    isAuthentificate() {
      return this.$store.getters.isAuthentificate();
    },
    menus() {
      return this.$store.state.menus;
    },
  },
};
</script>
