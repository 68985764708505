import * as firebase from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import { getAuth } from "firebase/auth";

// Get a Firestore instance
var app = firebase.initializeApp({
  apiKey: "AIzaSyALCoSamsKS8urR6Je5bBMuHI_j4mcRohQ",
  authDomain: "folclobasket-be9d5.firebaseapp.com",
  projectId: "folclobasket-be9d5",
  storageBucket: "folclobasket-be9d5.appspot.com",
  messagingSenderId: "15350022884",
  appId: "1:15350022884:web:90285c4e840baf1a64a2f1",
  measurementId: "G-NR200N9YEG",
});
export const db = getFirestore(app);

export const storageF = getStorage(app);

export const auth = getAuth(app);
